<template>
  <div class="chats">
    <div class="d-flex justify-content-center">
      <b-spinner
        v-if="$store.state['app-chat'].loads.chatLog"
        variant="primary"
        class="mr-1"
        small
        type="grow"
      />
    </div>

    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.senderId === formattedChatData.contact.id}"
    >

      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="secondary"
          :src="msgGrp.senderId === formattedChatData.contact.id ? formattedChatData.contact.avatar : profileUserAvatar"
        />
      </div>
      <div class="chat-body">

        <div>
          <div
            v-for="(msgData, idx) in msgGrp.messages"
            :key="msgData.time+String(idx)"
            class="chat-content d-flex"
          >

            <div
              v-if="msgGrp.senderId === formattedChatData.contact.id"
              class="d-block"
            />

            <div
              v-if="msgGrp.senderId !== formattedChatData.contact.id"
            />

            <p
              v-if="msgData.file"
              class=""
            >

              <img
                :src="msgData.file.url"
                class="image-chat"

                @click="visualizarImagem(msgData.file.url)"
              > <br>
              <small class="text-muted font-size-6">Data:
                {{ msgData.time }}</small>
            </p>
            <p
              v-else
            >
              {{ msgData.msg }} <br>
              <feather-icon
                v-if="msgData.readAt"
                v-b-tooltip.hover.v-primary="`Lido em: ${msgData.readAt}`"
                icon="CheckCircleIcon"
                size="10"
                class="text-success"
                style="margin-right: 4px"
              />
              <small class="text-muted font-size-6">Data: {{ msgData.time }}</small>
            </p>

          </div>
        </div>
      </div>

    </div>
    <b-modal
      v-model="modalShow"
      hide-footer
      size="lg"
    >
      <div v-if="imageRenderChat">
        <img
          :src="imageRenderChat"
          width="100%"
        >
      </div>

    </b-modal>
  </div>

</template>

<script>
import {
  BAvatar, BModal, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import Tooltip from '@/views/components/tooltip/Tooltip.vue'

export default {
  components: {
    BModal,
    Tooltip,
    BSpinner,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      }

      const chatLog = props.chatData.chat

      const formattedChatLog = []

      chatLog.forEach(msg => {
        formattedChatLog.push({
          senderId: msg.senderId,
          messages: [{
            msg: msg.message,
            time: msg.time,
            receivedAt: msg.receivedAt,
            readAt: msg.readAt,
            file: msg.file,
          }],
        })
      })

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    const imageRenderChat = ref(null)
    const modalShow = ref(false)
    const visualizarImagem = file => {
      imageRenderChat.value = file
      modalShow.value = true
    }

    const hideModal = () => {
      modalShow.value = false
    }

    return {
      formattedChatData,
      imageRenderChat,
      modalShow,
      visualizarImagem,
      hideModal,
    }
  },
}
</script>
<style lang="scss" scoped>
.image-chat {
  width: 100px;
  height: 100px;
  border: solid 3px #e5e9f2;
  cursor: pointer;

  &:hover {
    scale: 1.05;
    transition: 0.3s;
  }
}
</style>
